import React from 'react';

import st from './Spinner.module.scss';
import Loader from 'components/page-elements/Loader'

const Spinner = () => {
  return (
    <div className={st.spinner}>
      <Loader />
    </div>
  )
}

export default Spinner;