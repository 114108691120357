import urls from 'settings/urls';
import api from 'classes/IksAPI';

export const updateAnalytics = (case_id, data) => {
  const date = Date.now();

  // const key = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

  const dataToSend = data;

  return api.post(`${urls.analytics}/${case_id}`, { [`${date}`]: dataToSend });
}

