import React from 'react';
import st from './Loader.module.scss';
import Icon from 'components/ui/Icon';
import cn from 'classnames';

const Loader = ({type}) => {
  return (
    <div className={cn(st.wrap, st[type])}>
      <Icon name="spinner" />
    </div>
  )
}

export default Loader;