import qs from 'qs';

const createPath = ({ caseId, visitId, stepId = null }) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  
  if (stepId)
    query.step_id = stepId;
  else
    delete query.step_id;

  return `/case/${caseId}/${visitId}${Object.keys(query).length ? '?' + qs.stringify(query) : ''}`

  // if (stepId) {
  //   return `/case/${caseId}/${visitId}?step_id=${stepId}`;
  // } else {
  //   return `/case/${caseId}/${visitId}`;
  // }
};

export default createPath;