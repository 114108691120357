import { cloneDeep } from 'lodash';

const CHECKBOX_TYPE = 'check_box';

const createResultCheckboxStep = (step) => {
  const resStep = cloneDeep(step);

  return {
    ...resStep,
    id: `checkbox-result-${step.id}`,
    show_result: true,
    title: `Результат шага - ${resStep.title}`,
  };
};

const insertResultCheckboxSteps = (steps) => {
  const result = [];

  steps.forEach((step) => {
    const stepCopy = cloneDeep(step);
    result.push(stepCopy);

    if (
      stepCopy.blocks.find(({ block_type: type }) => type === CHECKBOX_TYPE)
    ) {
      const resultCheckboxStep = createResultCheckboxStep(stepCopy);
      result.push(resultCheckboxStep);
      stepCopy.next_step_id = resultCheckboxStep.id;
    }
  });

  return result;
};

const parseIdToStr = (obj) => ({
  ...obj,
  id: obj.id.toString(),
  next_step_id: obj.next_step_id !== null ? obj.next_step_id.toString() : null,
});

const parseVisitSteps = (steps) =>
  insertResultCheckboxSteps(steps).map(parseIdToStr);

const parseGlobalSteps = (steps) =>
  steps
    .map((step) => {
      const stepCopy = cloneDeep(step);

      return {
        ...stepCopy,
        is_global: true,
        next_step_id: null,
      };
    })
    .map(parseIdToStr);

const getAllSteps = (visitSteps, globalSteps) => {
  return [...parseVisitSteps(visitSteps), ...parseGlobalSteps(globalSteps)];
};

export default getAllSteps;
