import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import qs from 'querystring';
import RouteContext from '../../../store/RouteContext';
import { getUser, loginByIframe } from 'api/auth';
import Loader from 'components/page-elements/Loader';

const PrivateRoute = ({ Component, history, settings, ...rest }) => {
  const [auth, setAuth] = useState('pending');
  const [onlyCase, setOnlyCase] = useState(null);
  const token = localStorage.getItem('token');

  const { search } = useLocation();

  const searchObject = qs.parse(search.replace('?', ''));

  useEffect(() => {
    if (searchObject?.only_case)
      setOnlyCase(searchObject.only_case);

    if (searchObject?.app_key && searchObject?.user_key) {
      loginByIframe(searchObject.app_key, searchObject.user_key)
        .then(res => {
          getUser()
            .then(res => {
              setAuth('auth');
            })
            .catch(e => {
              setAuth('not_auth');
            })
        })

      return;
    }

    if (!token || auth !== 'pending') {
      setAuth('not_auth');

      return;
    }

    getUser()
      .then(res => {
        setAuth('auth');
      })
      .catch(e => {
        setAuth('not_auth');
      })
  }, []);// eslint-disable-line

  if (auth === 'pending')
    return <Loader />

  return (
    <Route
      {...rest}
      history={history}
      exact
      render={props => {
        if (auth === 'not_auth')
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

        if (onlyCase && rest.computedMatch.params?.id !== onlyCase)
          return <Redirect to={{ pathname: `/case/${onlyCase}`, search }} />

        return (
          <RouteContext
            settings={settings}
          >
            <Component {...props} key={Date.now()} />
          </RouteContext>
        )
      }}
    />
  )
}

export default PrivateRoute;