import React, { createContext, useEffect, useMemo, useState } from 'react';
import { saveProgress } from 'api/progress';
import getAllSteps from './case';
import createPath from 'helpers/createPath';

export const RouteContext = createContext();

const ContextComponent = ({ settings, children }) => {
  const [currentStep, setCurrentStep] = useState({});
  const [currentCase, setCurrentCase] = useState(null);
  const [currentVisit, setCurrentVisit] = useState(null);
  const [stepsInCase, setStepsInCase] = useState([]);
  const [progress, setProgress] = useState(null);
  const [globalSteps, setGlobalSteps] = useState([]);
  const [amChart, setAmChart] = useState(null);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const isLoaded = useMemo(
    () => !!currentCase && !!currentVisit && !!progress,
    [currentCase, currentVisit, progress],
  );

  const updateProgress = (data) => {
    setProgress(data);

    return saveProgress({
      id: currentCase.id,
      params: data,
    });
  };

  const updateStepData = ({ step_id, visit_id, data, was_changed }) => {
    const index = progress.findIndex(step => step.id === step_id && step.visit_id.toString() === visit_id.toString());
    let arr = [...progress];
    arr[index] = {...arr[index], data, was_changed};

    setProgress(arr);
  };

  const updateCaseData = (caseData) => {
    setCurrentCase(caseData.case);

    if (caseData.allGlobalSteps)
      setGlobalSteps(
        caseData.allGlobalSteps.map((step) => ({
          ...step,
          is_global: true,
          next_step_id: null, // TEMP
        })),
      );
  };

  const updateSteps = (newData) => {
    const curStepId = (newData.id || '0').toString();
    const current = {
      id: curStepId,
      title: newData?.title || currentVisit.title,
      visit_id: newData.visit_id || currentVisit.id,
      url: createPath({
        caseId: newData.clinical_case_id || currentVisit.clinical_case_id,
        visitId: newData.visit_id || currentVisit.id,
        stepId: curStepId
      }),
      prev_url: currentStep.url || null,
      data: null
    }
    let prev = currentStep;

    if (!current.prev_url && current.id !== '0' && progress && progress.length) { // get prev from progress
      prev = progress[progress.length - 1]
      current.prev_url = prev.url
    }
    
    setCurrentStep(current);

    if (!progress && curStepId === 0)
      return;

    // progress
    let arr = progress || [];
    const curIndex = arr.findIndex(item => item.id === current.id && item.visit_id.toString() === current.visit_id.toString());

    if (curIndex > -1) // no progress update, it's go back
      return;

    if (current.prev_url) { // else first elem in progress, should be with step_id: '0'
      const prevIndex = arr.findIndex(item => item.url === prev.url);

      if (prevIndex < 0) {
        console.info('NO PREV, ERROR?', prev, arr);

        return;
      }

      if (prevIndex < arr.length - 1) // remove all before prev
        arr.length = prevIndex + 1;
    }

    arr.push(current);
    updateProgress(arr);
  }

  useEffect(() => {
    if (!currentCase) return;

    const visitSteps = currentCase.visit.reduce(
      (steps, item) => [...steps, ...item.steps],
      [],
    );

    const globalSteps = currentCase.steps;
    const allSteps = getAllSteps(visitSteps, globalSteps).map(i => ({
      ...i,
      clinical_case_id: currentCase.id
    }));

    setStepsInCase(allSteps);
  }, [currentCase]);

  return (
    <RouteContext.Provider
      value={{
        currentStep,
        currentCase,
        currentVisit,
        progress,
        globalSteps,
        stepsInCase,
        isLoaded,
        amChart,
        showMobileNav,
        settings,
        setCurrentStep,
        setCurrentCase,
        setCurrentVisit,
        setStepsInCase,
        setProgress,
        setGlobalSteps,
        updateStepData,
        updateProgress,
        updateCaseData,
        updateSteps,
        setAmChart,
        setShowMobileNav
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export default ContextComponent;
