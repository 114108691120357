import React from 'react';

const Routes = [
  {
    path: '/',
    component: React.lazy(() => import(/* webpackPreload: true */'views/Home')),
    auth: true
  },
  {
    path: '/login',
    component: React.lazy(() => import(/* webpackPreload: true */'views/Login'))
  },
  {
    path: '/case/:id',
    component: React.lazy(() => import(/* webpackPreload: true */'views/Case/Redirect')),
    auth: true
  },
  {
    path: '/case/:id/:visit_id',
    component: React.lazy(() => import(/* webpackPreload: true */'views/Case')),
    auth: true
  },
  {
    path: null,
    component: React.lazy(() => import('views/404')),
  },
];

export default Routes;