import { updateAnalytics } from 'api/analytics';
import qs from 'qs';

const wrapper = document.getElementById('root');

const generateEvent = ({ key, data, case_id = null }) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  
  if (query.is_admin)
    return;

  const event = new CustomEvent(
    'analytics-event', {
    detail: { [key]: data }
  });
  wrapper.dispatchEvent(event);

  case_id && process.env.NODE_ENV !== 'development' && updateAnalytics(case_id, { [key]: filterEventData(data) });
};

const filterEventData = (data) => {
  const res = {};

  Object.keys(data).forEach(key => {
    if (key === 'answer') {
      res[key] = {
        id: data[key].id,
        block_type: data[key].block_type,
        content: data[key].content,
        result: data[key].result
      }

      return;
    }

    if (key === 'case') {
      res[key] =  {
        id: data[key].id,
        title: data[key].title
      }

      return;
    }

    if (key === 'step') {
      res[key] =  {
        id: data[key].id,
        visit_id: data[key].visit_id,
        title: data[key].title
      }

      if (data[key].step_type)
        res[key].step_type = data[key].step_type;

      if (data[key].clinical_case_id)
        res[key].clinical_case_id = data[key].clinical_case_id;

      return;
    }

    res[key] = data[key];
  })

  return res;
}

export default generateEvent;