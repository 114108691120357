import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem('token');

if (token)
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export default apiClient;