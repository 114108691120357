import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Routes from 'routes';

import Loader from 'components/page-elements/Loader';
import PrivateRoute from 'components/auth/PrivateRoute';
import { getSettings } from 'api/general';
import Spinner from 'components/ui/Spinner';
import colorNames from 'settings/theme-color-names';
import createBgUrl from 'helpers/createBgUrl';

const App = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [bgImage, setBgImg] = useState('');
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    getSettings()
      .then(res => {
        setLoaded(true);
        applySettings(res);
      })
      .catch(e => {
        setLoaded(true);
      })
  }, [])

  const applySettings = (data) => {
    if (!data)
      return;

    setSettings(data);

    const root = document.querySelector('#root');
      
    if (root)
      colorNames.forEach(color => {
        if (data[color])
          root.style.setProperty(`--${color}`, data[color]);
      });
      
    if (data.attach && data.attach.path)
      setBgImg(createBgUrl(data.attach.path));
  }

  return (
    <div className="iks-app">
      {!loaded && (
        <Spinner />
      )}

      <Router>
        <main className="iks-main">
          <div 
            className="iks-main-bg"
            style={{
              backgroundImage: bgImage
            }}
          ></div>
          
          <React.Suspense fallback={<Loader />}>
            <Switch>
              {Routes.map(({ component: Cmp, ...route }, index) => {
                return route.auth ? (
                  <PrivateRoute
                    key={index}
                    {...route}
                    Component={Cmp}
                    history={useHistory}
                    exact
                    settings={settings}
                  />
                ) : (
                  <Route
                    key={index}
                    {...route}
                    render={props => <Cmp {...props} />}
                    history={useHistory}
                    exact
                  ></Route>
                )
              })}
            </Switch>
          </React.Suspense>
        </main>
      </Router>
    </div>
  )
}

export default App;