import urls from 'settings/urls';
import api from 'classes/IksAPI';

export const getProgress = (id) => {
  return api.get(`${urls.progress}/${id}`)
    .then(res => {
      return Promise.resolve(res);
    });
}

export const saveProgress = (data) => {
  return api.post(`${urls.progress}/${data.id}`, { data: data.params })
    .then(res => {
      return Promise.resolve(res);
    });
}

export const resetProgress = (caseId) => {
  return api.post(`${urls.reset_progress}/${caseId}`)
    .then(res => {
      return Promise.resolve(res);
    })
}

export const sendTotalPercent = (data) => {
  return api.post(`${urls.postAmchart}/${data.id}`, { ...data.params })
    .then(res => {
      return Promise.resolve(res);
    });
}
