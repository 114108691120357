import urls from 'settings/urls';
import apiClient from 'utils/apiClient';

class IksAPI {
  get(url, params) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${urls.api}${url}`, params)
        .then(res => {
          resolve(res.data.data || res.data);
        })
        .catch(e => {
          reject(e);
        })
    });
  }

  post(url, params) {
    return new Promise((resolve, reject) => {
      apiClient.get(urls.csrf)
        .then(() => {
          apiClient.post(`${urls.api}${url}`, params)
            .then(res => {
              resolve(res.data.data || res.data);
            })
            .catch(e => {
              reject(e);
            })
        })
        .catch(e => {
          console.info('csrf error', e);
        })
    });
  }
}

export default new IksAPI();