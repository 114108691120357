const urls = {
  api: '/api/',
  csrf: '/sanctum/csrf-cookie',

  login: 'login',
  login_pin: 'pin',
  login_token: 'iframe',
  user: 'user',
  case: 'case',
  cases: 'cases',
  visits: 'visits',
  step: 'step',
  progress: 'progress-case',
  reset_progress: 'clearcase-progress',
  postAmchart: 'post-amchart',
  analytics: 'analytic',
  settings: 'get-settings'
};

export default urls;