import urls from 'settings/urls';
import api from 'classes/IksAPI';
import apiClient from 'utils/apiClient';
import generateEvent from 'helpers/sendAnalytics';
import { USER_AUTH_KEY } from 'constants/analyticsEvents';

export const login = (data) => {
  return api.post(urls.login, data)
    .then(res => {
      localStorage.setItem('token', res.access_token);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;

      return Promise.resolve(res);
    });
}

export const loginPin = (pin) => {
  return api.post(urls.login_pin, { pin })
    .then(res => {
      localStorage.setItem('token', res.access_token);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;

      return Promise.resolve(res);
    })
}

export const loginByIframe = (app_key, user_key) => {
  return api.post(urls.login_token, { 
    app_key,
    user_key  
  })
    .then(res => {
      localStorage.setItem('token', res.access_token);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;

      return Promise.resolve(res);
    })
    .catch(e => {
      let err = e.response;

      if (err && err.data && err.data.status === 401)
        window.location.href= '/login';
    })
}

export const getUser = () => {
  return api.get(urls.user)
    .then(res => {
      generateEvent({ key: USER_AUTH_KEY, data: res.user.id })

      return Promise.resolve(res);
    });
}